import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoading: false,
  hasData: false,
  error: "",
  formStatus: {
    status: false,
    message: ""
  }
};
const flightDisruptionCertSlice = createSlice({
  name: "flightDisruptionCert",
  initialState,
  reducers: {
    submitFormRequest: (state, action) => {
      state.isLoading = true;
    },
    submitFormSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.successData = action.payload;
      if (state.successData.message === "Case Created" || state.successData.message === "Mail sent successfully") {
        state.formStatus.message = "Case Created";
        state.formStatus.status = true;
      }
    },
    submitFormFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    clearFormSuccessResponse: state => {
      state.formStatus.message = "";
      state.formStatus.status = false;
    }
  }
});
export const {
  submitFormRequest,
  submitFormFailure,
  submitFormSuccess,
  clearFormSuccessResponse
} = flightDisruptionCertSlice.actions;
export default flightDisruptionCertSlice.reducer;